body {
    background-color: #f4f6f8;
    font-family: 'trade-gothic-next', sans-serif;
    font-weight: 400;
    margin: 0;
}

img {
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
}

.MuiTableContainer-root + .loadMoreContainer {
    border-top: 1px solid rgba(0, 0, 0, 0.12);
}

.MuiModal-root .location-sticky-header {
    top: 0;
}

.SnackbarItem-message {
    font-size: 14px !important;
}

.SnackbarItem-message .MuiSvgIcon-root {
    font-size: 30px !important;
}

.ReactModal__Overlay {
    z-index: 1500 !important;
}
.flex {
    display: flex;
}
.flex-row {
    flex-direction: row;
    justify-content: space-between;
}

/* label  */

/* Date Range Picker */

.flatpickr-months .flatpickr-month,
.flatpickr-weekdays,
span.flatpickr-weekday,
.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month
    .flatpickr-monthDropdown-months
    .flatpickr-monthDropdown-month {
    background: #002e5a;
    font-family: 'trade-gothic-next-condensed';
}

.flatpickr-calendar.arrowTop:after {
    border-bottom-color: #002e5a;
}

.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
    background: #002e5a;
    border-color: #002e5a;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n + 1)),
.flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n + 1)) {
    -webkit-box-shadow: -10px 0 0 #002e5a;
    box-shadow: -10px 0 0 #002e5a;
}

span.flatpickr-weekday {
    color: #ffffff;
    font-size: 14px;
}

.rangeMode .flatpickr-day {
    font-size: 13px;
}
.fontSizeIncrease .MuiFormHelperText-contained {
    font-size: 0.9rem;
}
.displayNone {
    display: none;
}

@media (max-width: 600px) {
    .mobile-w100 {
        width: 100%;
    }
    .css-8vvsi0 > .MuiButton-root:first-child {
        margin-right: 0 !important;
    }
}

.MuiButtonBase-root.disabled-style {
    background-color: #8080803d;
}

.column-Sticky {
    position: sticky;
    left: 0;
    background: white;
}

.webkit-over-ios {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
@supports (-webkit-overflow-scrolling: touch) {
    .webkit-over-ios {
        /* height:calc(100vh+300px); */
        max-height: calc(100vh - 200px);
        max-width: 100%;
        /* width:100%; */
    }
}

.mobile__pdf__container #viewerContainer .pdfViewer {
    overflow: hidden;
    overflow: -moz-hidden-unscrollable;
}
.mobile__pdf__container .hidden {
    display: none;
}
#pdfDocument:has(.react-pdf__Document) {
    overflow: scroll;
}
.react-pdf__Page__canvas {
    margin: 0 auto;
    /* width: 90% !important; */
    height: 100% !important;
}
.webkit-over-ios {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}
@supports (-webkit-overflow-scrolling: touch) {
    .webkit-over-ios {
        /* height:calc(100vh+300px); */
        max-height: calc(100vh - 200px);
        max-width: 100%;
        /* width:100%; */
    }
}
/* .MuiPaper-root.MuiPaper-outlined.MuiPaper-rounded.MuiTableContainer-root{
overflow-x:scroll;
-webkit-overflow-scrolling: touch;
} */
.pdf-box:last-of-type {
    margin-bottom: 40vh;
}
#doc-container {
    margin-bottom: 40vh;
}
.react-transform-wrapper.transform-component-module_wrapper__SPB86 {
    max-width: 100%;
    max-height: calc(100vh - 50px);
}

.transform-component-module_wrapper__SPB86 {
    width: 100% !important;
}
div.filtertimerange .MuiPaper-root {
    overflow: visible;
}

div.react-datepicker-popper {
    z-index: 9999999999;
}

.MuiDialog-container .MuiPaper-root:not(.MuiTableContainer-root) {
    overflow: visible;
}
